import React from 'react'
import { Modal } from 'react-bootstrap';
// import { PiSealCheckFill } from 'react-icons/pi'
import { Link } from 'react-router-dom'

const WithDrawal = ({ show, close,onSubmit }) => {
    if (!show) return null;
    const handleSubmit = () => {
        close();
        onSubmit();
    }

    return (
        <>
        <div className="withdral-request-outer">
            <Modal show={show} onHide={close}>
                <div className={`withdrawal-outer`}>
                    <div className="withdrawal-inner ">
                        <div className="heading">
                            <h3>Enter Withdrawal Amount</h3>
                        </div>
                        <div className="withdrawal-amount">
                            <input type="text" placeholder='Enter Withdrawal Amount' />
                        </div>

                        {/* <p className='verify-account'><span><PiSealCheckFill />
                        </span> Verify Bank Account</p> */}
                        <p className='warning'>! Not Verify Your Bank Account <Link to='' onClick={handleSubmit}>Click here</Link></p>

                        <div className="check-group">
                            <input type="checkbox" name="" id="" />
                            <label htmlFor="">Your request verify by camdell after then your amount transfer to your account and it take time for verification process</label>
                        </div>

                        <div className="withdrawal-button">
                            <Link to='' className='button'>Submit</Link>
                        </div>
                    </div>
                </div>
            </Modal>
                
        </div>
            

        </>
    )
}

export default WithDrawal