import { BaseUrl } from '../Components/BaseURL/BaseUrl';

const ApiService = {
    register: async (data) => {
        try {
            const response = await fetch(`${BaseUrl}/user/user-register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }

            return await response.json();
        } catch (error) {
            console.error('Error during registration:', error);
            throw error; 
        }
    },
    UserLogin: async (data) => {
        try {
            const response = await fetch(`${BaseUrl}/user/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }

            return await response.json();
        } catch (error) {
            console.error('Error during login:', error);
            throw error; 
        }
    },
    Merchantlogin: async (data) => {
        try {
            const response = await fetch(`${BaseUrl}/merchant/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }

            return await response.json();
        } catch (error) {
            console.error('Error during login:', error);
            throw error; 
        }
    },
    Manager_cashier: async (data) => {
        try {
            const response = await fetch(`${BaseUrl}/McLogin`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }

            return await response.json();
        } catch (error) {
            console.error('Error during login:', error);
            throw error; 
        }
    },
    applyMerchant: async (data) => {
        try {
            const response = await fetch(`${BaseUrl}/user/applymerchant`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }

            return await response.json();
        } catch (error) {
            console.error('Error during registration:', error);
            throw error; 
        }
    },
    applycoupon: async (data) => {
        try {
            const response = await fetch(`${BaseUrl}/user/applycoupon`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }

            return await response.json();
        } catch (error) {
            console.error('Error during registration:', error);
            throw error; 
        }
    },
    operatorLogin: async (data) => {
        try {
            const response = await fetch(`${BaseUrl}/user/operatorLogin`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }

            return await response.json();
        } catch (error) {
            console.error('Error during registration:', error);
            throw error; 
        }
    },
    getCouponData: async () => {
        try {
            const response = await fetch(`${BaseUrl}/user/draftcoupons`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }
    
            const data = await response.json();
            // console.log(data);
            return data;
          
        } catch (error) {
            console.error('Error fetching coupon data:', error);
            throw error; 
        }
    },
    getCouponData: async () => {
        try {
          const response = await fetch(`${BaseUrl}/user/draftcoupons`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
    
      getCouponById: async (id) => {
        try {
          const response = await fetch(`${BaseUrl}/user/coupons/${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error(`Error fetching coupon with ID ${id}:`, error);
          throw error;
        }
      },
    
    
};

export default ApiService;
