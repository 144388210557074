import React, { useEffect, useState } from 'react';
import { FaAngleRight, FaCirclePlus } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ApiService from '../../../services/Api';

const DraftCupon = () => {
    const [cupon, setCoupon] = useState([]); // State to hold coupon data
    const [loading, setLoading] = useState(true); // Loading state
    const [successMessage, setSuccessMessage] = useState('');
    const [submitError, setSubmitError] = useState('');
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState({});

    const options = {
        loop: true,
        responsiveClass: true,
        nav: false,
        margin: 20,
        autoplay: false,
        responsive: {
            0: {
                items: 1,
                dots: false,
            },
            767: {
                items: 2,
                dots: false,
            },
            1200: {
                items: 3,
                dots: true,
            },
        },
    };

    useEffect(() => {
        handleGetCouponData();
    }, []);

    const handleGetCouponData = async () => {
        try {
            const result = await ApiService.getCouponData(); // Assuming getCouponData is defined in ApiService
            if (result.status === 200 || result.status === 201) {
                setCoupon(result.data);
                setSuccessMessage('Coupons fetched successfully!');
                setShow(true); // Show any relevant UI, if needed
            } else {
                setSubmitError('Failed to fetch coupons. Please try again.');
            }
        } catch (err) {
            if (err.status === 422) {
                setErrors(err.errors); // Handle specific validation errors, if applicable
            } else {
                setSubmitError(err.message || 'An unexpected error occurred.');
            }
        } finally {
            setLoading(false); // Set loading to false after data is fetched
        }
    };

    return (
        <>
            <div className="draft-cupon-outer">
                <div className="container">
                    <div className="draft-cupon-inner">
                        <div className="heading">
                            <h3>draft coupons</h3>
                            <Link to={'/draftcupon'}>View all <FaAngleRight /></Link>
                        </div>
                        {loading ? ( // Show loading state
                            <div>Loading...</div>
                        ) : (
                            <div className="cupon-row">
                                <OwlCarousel className='owl-theme' {...options}>
                                    {cupon.map((i) => (
                                        <div className="item" key={i.id}>
                                            <div className="item-inner">
                                                <div className="top">
                                                    <div className="img-content">
                                                        <img src={i.img} alt={i.btype} />
                                                        <h2>{i.btype}</h2>
                                                    </div>
                                                    <div className="add-new">
                                                        <Link to={'/newdeals'}><FaCirclePlus /> Add new deals</Link>
                                                    </div>
                                                </div>
                                                <div className="bottom row">
                                                    <div className="col-6">Cat: {i.category_name}</div>
                                                    <div className="col-6">Started: {i.enddate}</div>
                                                    <div className="col-6">Sub-Cat: {i.subcategory_name}</div>
                                                    <div className="col-6">Ended: {i.enddate}</div>
                                                    <div className="col-6">Deals: {i.deals}</div>
                                                    <div className="col-6"><Link to={`/coupons/${i.id}`}>View</Link></div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default DraftCupon;
