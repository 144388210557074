import React from 'react'
import { FaCirclePlus } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

const Draftcupon = () => {
    const cupon = [
        {
            img: 'assets/Image/swiggy.png',
            name: 'Swiggy',
            mode: 'Online',
            cat: "Food  & Drinks",
            started: '08-feb-2024',
            sub: 'Dinners',
            ended: '30-feb-2024',
            deals: 'Discount',
            status: 'pending',
        },
        {
            status: 'approval',
            img: 'assets/Image/dominoz.png',
            name: 'MC Dominos',
            mode: 'Offline',
            cat: "Food  & Drinks",
            started: '08-feb-2024',
            sub: 'Dinners',
            ended: '30-feb-2024',
            deals: 'vouchers'
        },
        {
            status: 'approval',
            img: 'assets/Image/dominoz.png',
            name: 'MC Dominos',
            mode: 'Offline',
            cat: "Food  & Drinks",
            started: '08-feb-2024',
            sub: 'Dinners',
            ended: '30-feb-2024',
            deals: 'vouchers'
        },

        {
            img: 'assets/Image/swiggy.png',
            name: 'Swiggy',
            mode: 'Online',
            cat: "Food  & Drinks",
            started: '08-feb-2024',
            sub: 'Dinners',
            ended: '30-feb-2024',
            deals: 'Discount',
            status: 'pending',
        },
        {
            status: 'approval',
            img: 'assets/Image/dominoz.png',
            name: 'MC Dominos',
            mode: 'Offline',
            cat: "Food  & Drinks",
            started: '08-feb-2024',
            sub: 'Dinners',
            ended: '30-feb-2024',
            deals: 'vouchers'
        },
        {
            status: 'approval',
            img: 'assets/Image/dominoz.png',
            name: 'MC Dominos',
            mode: 'Offline',
            cat: "Food  & Drinks",
            started: '08-feb-2024',
            sub: 'Dinners',
            ended: '30-feb-2024',
            deals: 'vouchers'
        },

        {
            img: 'assets/Image/swiggy.png',
            name: 'Swiggy',
            mode: 'Online',
            cat: "Food  & Drinks",
            started: '08-feb-2024',
            sub: 'Dinners',
            ended: '30-feb-2024',
            deals: 'Discount',
            status: 'pending',
        },
        {
            status: 'approval',
            img: 'assets/Image/dominoz.png',
            name: 'MC Dominos',
            mode: 'Offline',
            cat: "Food  & Drinks",
            started: '08-feb-2024',
            sub: 'Dinners',
            ended: '30-feb-2024',
            deals: 'vouchers'
        },
        {
            status: 'approval',
            img: 'assets/Image/dominoz.png',
            name: 'MC Dominos',
            mode: 'Offline',
            cat: "Food  & Drinks",
            started: '08-feb-2024',
            sub: 'Dinners',
            ended: '30-feb-2024',
            deals: 'vouchers'
        },
    ]
    return (
        <>
            <div className="draft-cupon-page draft-cupon-outer">
                <div className="container">
                    <div className="draft-cupon-inner cupon-row">
                        <div className="row">
                            {
                                cupon.map((i) => {
                                    return (
                                        <div className="item col-12 col-md-6 col-xl-4">
                                            <div className="item-inner">
                                                <div className="top">
                                                    <div className="img-content">
                                                        <img src={i.img} alt="" />
                                                        <h2>{i.name}</h2>
                                                        <span className='cupon-mode'>{i.mode}</span>
                                                    </div>
                                                   
                                                    <div className="add-new">
                                                        <Link to={'/newdeals'}><FaCirclePlus /> Add new deals</Link>
                                                    </div>
                                                </div>
                                                <div className="bottom row">
                                                    <div className="col-6"><b>Cat : </b>{i.cat}</div>
                                                    <div className="col-6"><b>Started :</b> {i.started}</div>
                                                    <div className="col-6"><b>Sub - Cat  :</b> {i.sub}</div>
                                                    <div className="col-6"><b>Ended :</b> {i.ended}</div>
                                                    <div className="col-6"><b>Deals :</b> {i.deals}</div>
                                                    <div className="col-6"><Link to={''}>View</Link></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Draftcupon
