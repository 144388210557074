import React from 'react'
import MearchantHeader from '../Merchant/MearchantHeader'
import MerchantCuponDescription from '../Merchant/MerchantCupondescription/MerchantCuponDescription'
import PageBanner from '../PageBanner'

const MerchantCuponDescriptionPage = () => {
  return (
    <>
    <MearchantHeader/>
        <PageBanner title='coupons description'/>
        <MerchantCuponDescription/>
    </>
  )
}

export default MerchantCuponDescriptionPage
