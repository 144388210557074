import React from 'react'
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ChangePassword = ({ show, close }) => {
    return (
        <>


                <Modal show={show} onHide={close}>
                    <div className="change-password-outer">
                        <div className="container">
                            <div className="cahnge-password-inner">
                                <div className="change-password row">
                                    <div className="col-12 col-sm-6 left">
                                        <div className="img-content">
                                            <img src='assets/Image/changepassword.png' alt="" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 right">
                                        <div className="inner">

                                            <div className="heading">
                                                <h3>Change password</h3>
                                            </div>
                                            <form method="post">
                                                <div className="input-group">
                                                    <label htmlFor="">Create Password</label>
                                                    <input type="password" name='newpassword' id='newpassword' placeholder='Enter your password' />
                                                </div>

                                                <div className="input-group">
                                                    <label htmlFor="">Confirm Password</label>
                                                    <input type="password" name='cpassword' id='cpassword' placeholder='Enter your password' />

                                                </div>


                                                <Link to='' onClick={close} className='button'>Save</Link>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

        </>
    )
}

export default ChangePassword