import React from 'react';
import ApplyMerchant from './LoginRegister/ApplyMerchant';
import MearchantHeader from './MearchantHeader';
import PageBanner from '../PageBanner';
import ApprovalPandding from '../Merchant/LoginRegister/ApprovalPandding';
import ApprovalRejected from '../Merchant/ApprovalRejected';

import MerchantDesktop from '../Merchant/MerchantDesktop';
const MeachantLoginPage = () => {
  const approvalStatus = localStorage.getItem('is_approved');
  return (
    <>
    {approvalStatus != '2' && <MearchantHeader />}
    {approvalStatus != '2' && <PageBanner title='Merchants Login' />}
    {approvalStatus === '0' && <ApplyMerchant />}  
    {approvalStatus === '1' && <ApprovalPandding />} 
    {approvalStatus === '2' && <MerchantDesktop />}
    {approvalStatus === '3' && <ApprovalRejected />}
  </>
  );
}

export default MeachantLoginPage;
