import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../../services/Api';
const OperatorLogin = () => {

    const navigate = useNavigate();
  useEffect(() => {
      const subManager = localStorage.getItem('sub_manager');
      if (subManager == '1') {
        navigate('/unauthorized');
      }
    }, [navigate]);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        operator: '',
        password: '',
      });
    
      const [errors, setErrors] = useState({});
      const [submitError, setSubmitError] = useState('');
      const [successMessage, setSuccessMessage] = useState('');
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' })); 
        setSubmitError('');
      };
    
      const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email) newErrors.email = 'Email is required';
        else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid';
        if (!formData.operator) newErrors.operator = 'Operator selection is required';
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,12}$/;
        if (!formData.password) {
          newErrors.password = 'Password is required';
        } else if (!passwordRegex.test(formData.password)) {
          newErrors.password = 'Password must be 6-12 characters long, include at least one uppercase letter, one lowercase letter, and one number.';
        }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        try {
          const result = await ApiService.operatorLogin(formData); // Adjust API call as necessary
          if (result.status === 200 || result.status === 201) {
            setSuccessMessage('Register successful!');
            setFormData({ name: '', email: '', operator: '', password: '' }); 
          } else {
            setSubmitError('Register failed. Please try again.');
          }
        } catch (err) {
            if (err.status === 422) {
                setErrors(err.errors);
            } else {
                setSubmitError(err.message || 'An unexpected error occurred.'); 
            }
        }
      };
     
      return (
        <div className="operator-login-outer">
          <div className="container">
            <div className="operator-login-inner">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="inputgroup">
                      <label htmlFor="name">Name</label>
                      <input type="text" id='name' name='name' placeholder='Enter the name' value={formData.name} onChange={handleChange} />
                      {errors.name && <span className="error">{errors.name}</span>}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="inputgroup">
                      <label htmlFor="email">Email</label>
                      <input type="email" id='email' name='email' placeholder='Username@explain.com' value={formData.email} onChange={handleChange} />
                      {errors.email && <span className="error">{errors.email}</span>}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="inputgroup">
                      <label htmlFor="operator">Select the operator</label>
                      <select id='operator' name='operator' value={formData.operator} onChange={handleChange}>
                        <option value="" disabled>Select the operator</option>
                        <option value="manager">Operator Manager</option>
                        <option value="cashier">Operator Shop Enter Man</option>
                      </select>
                      {errors.operator && <span className="error">{errors.operator}</span>}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="inputgroup">
                      <label htmlFor="password">Password</label>
                      <input type="password" id='password' name='password' placeholder='********' value={formData.password} onChange={handleChange} />
                      {errors.password && <span className="error">{errors.password}</span>}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="submit-button">
                      <button type="submit" className="button">Submit</button>
                    </div>
                  </div>
                  {submitError && <span className="error">{submitError}</span>}
                  {successMessage && <span className="success">{successMessage}</span>}
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    };

export default OperatorLogin
