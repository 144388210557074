import React from 'react'
import CreateOperatorlogin from './Desktop/CreateOperatorlogin'
import Cupons from './Desktop/Cupons'
import DraftCupon from './Desktop/DraftCupon'
import Statistics from './Desktop/Statistics'
import Subscription from './Desktop/Subscription'
import MearchantHeader from './MearchantHeader'
import PageBanner from '../PageBanner'
import Editdealform from './AddNewDeals/EditDeal'

const CouponEdit = () => {
  return ( 
    <>
    <MearchantHeader/>
      <PageBanner title='Merchant'/>
      <Editdealform/>
    </> 
  )
}

export default CouponEdit;
