import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element, allowedRoles }) => {
  const role = localStorage.getItem('role'); 
  const isApproved = localStorage.getItem('is_approved'); 

  if (!role) {
    return <Navigate to="/home" />;
  }

  if ((role === 'user' || role === 'merchant')) {
    const rolesToAllow = isApproved === '2' ? ['user', 'merchant'] : ['user'];

    if (rolesToAllow.includes(role)) {
      return element;
    } else {
      return element;
    }
    
  }
  else{
    if (allowedRoles.includes(role)) {  
      return element; 
    }
    else{
      if (!allowedRoles.includes(role)) {
        return <Navigate to="/unauthorized" />;
      }
    }
    
  }

 
};

export default ProtectedRoute;

// worked
