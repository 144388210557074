import React from 'react'
import PageBanner from '../PageBanner'
import MearchantHeader from './MearchantHeader'
import MerchntStatic from './Statics/MerchntStatic'

const MearchantStaticsPage = () => {
  return (
    <>
      <MearchantHeader/>
      <PageBanner title='Statistics'/>
      <MerchntStatic/>
    </>
  )
}

export default MearchantStaticsPage
