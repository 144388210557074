import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CuponAddPopup = ({ close, onConfirm, onFirstConfirmation }) => {
    const [showSecondModal, setShowSecondModal] = useState(false);

    const handleFirstConfirmation = () => {
        onConfirm();
        onFirstConfirmation(); // Store data or handle logic
        setShowSecondModal(true); // Show the second modal
    };

    const handleFinalConfirmation = () => {
        onConfirm(); // Confirm submission
        close(); // Close the popup
    };

    return (
        <>
            <div className="cuponadd-outer">
                <div className="cuponadd-inner">
                    <div className="heading">
                        <h3>Verification</h3>
                    </div>
                    <p>Once you submit, your coupon can’t be edited or deleted</p>

                    <div className="button-group">
                        <Link className="button" onClick={handleFirstConfirmation}>Yes</Link>
                        <Link className="outline-button" onClick={close}>No</Link>
                    </div>
                </div>
            </div>

            <Modal show={showSecondModal} onHide={() => setShowSecondModal(false)}>
                <div className="cuponadd-outer">
                    <div className="cuponadd-inner">
                        <div className="heading">
                            <h3>Final Verification</h3>
                        </div>
                        <p>Do you want first preference on your coupon sales?</p>

                        <div className="button-group">
                            <Link className="button" onClick={handleFinalConfirmation}>Yes</Link>
                            <Link className="outline-button" onClick={() => setShowSecondModal(false)}>No</Link>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CuponAddPopup;
