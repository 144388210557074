import React, { useState,useEffect } from 'react'
import { Container, Dropdown, DropdownButton, DropdownItem, Modal, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { FaMapMarkerAlt, FaSearch } from 'react-icons/fa'
import { FaRegBell } from 'react-icons/fa6'
import { MdOutlineQrCodeScanner } from 'react-icons/md'
import { Link,useNavigate } from 'react-router-dom'
import Operatorlogin from './Operator/Operatorlogin'
import Login from './User/Login'
import Signup from './User/Signup'
import Location from './User/Location'
import ScanerModel from './User/ScanerModel'
import Notification from './User/Notification'
import Merchantlogin from './Merchant/LoginRegister/Merchantlogin'
import DeleteAccount from './User/DeleteAccount'
import { IoLogOut } from 'react-icons/io5';
import { BaseUrl } from './BaseURL/BaseUrl';
import { useAuth } from '../context/AuthContext'; 

const Header = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const navigate = useNavigate();
    const { logout } = useAuth(); 

    const [userRole, setUserRole] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    
  useEffect(() => {
        const role = localStorage.getItem('role');
        const token = localStorage.getItem('token');
        const loggedIn = !!token;
        const approved = loggedIn ? localStorage.getItem('is_approved') === '1' : false; 
        setUserRole(role);
        setIsLoggedIn(loggedIn);
        setIsApproved(loggedIn ? approved : false); // Defa
    }, []);

    const handleLogout = async () => {
        try {
            // API call to log out the user
            const response = await fetch(`${BaseUrl}/user/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`, // Send token if needed
                },
            });
    
            if (response.ok) {
                localStorage.removeItem('UserData');
                localStorage.removeItem('role');
                localStorage.removeItem('token');
                localStorage.removeItem('is_approved');
                localStorage.removeItem('sub_manager');
                
                logout(); 
                // Redirect to login page
                navigate('/home');
            } else {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const handleClose = () => setShowModal(false);

    const handleMemberLogin = () => {
        setModalContent(<Login onClose={handleClose} onOpenMemberSignUp={handleMemberSignUp} />);
        setShowModal(true);
    };
    const handleMemberSignUp = () => {
        setModalContent(<Signup onClose={handleClose} onOpenMemberLogin={handleMemberLogin} />);
        setShowModal(true);
    };

    const handleMerchantLogin = () => {
        setModalContent(<Merchantlogin onClose={handleClose} />);
        setShowModal(true);
    };

    const handleOperatorLogin = () => {
        setModalContent(<Operatorlogin onClose={handleClose} />);
        setShowModal(true);
    };

    const handleOpenLocation = () => {
        setModalContent(<Location onClose={handleClose} />)
        setShowModal(true);
    }
    const handlenotificition = () => {
        setModalContent(<Notification onClose={handleClose} />)
        setShowModal(true);
    }
    const handleOpenscaer = () => {
        setModalContent(<ScanerModel onClose={handleClose} />)
        setShowModal(true);
    }

    const handledeleteAccount = () => 
    {
        setModalContent (<DeleteAccount onClose={handleClose}/>)
        setShowModal(true);
    }

    return (
        <>
            <Navbar expand="lg" className="">
                <div className="top">
                    <Container>
                        <div className="header-inner row">


                            <div className="logo col-2">
                                <div className="img-content">
                                    <img src="assets/Image/logo.png" alt="Header Logo" />

                                </div>
                            </div>
                            <div className="info col-10">
                                <div className="search">
                                    <input type="text" name="search" id="search" placeholder='Search for the coupons , deals and  offers' />
                                    <button><FaSearch /></button>
                                </div>
                                <div className="points">
                                    <img src="assets/Image/Coins.png" alt="Coins Logo" />
                                    <p>100</p>
                                </div>



                                {!isLoggedIn && (
                            <DropdownButton id="dropdown-basic-button" className="login-dropdown" title="Members Login">
                                <Dropdown.Item as={Link} onClick={handleMemberLogin}>Member Login</Dropdown.Item>
                                <Dropdown.Item as={Link} onClick={handleMerchantLogin}>Merchant Login</Dropdown.Item>
                                <Dropdown.Item as={Link} onClick={handleOperatorLogin}>Operator Login</Dropdown.Item>
                            </DropdownButton>
                        )}
                       

                                <div className="scanner">
                                    <Link  onClick={handleOpenscaer}><MdOutlineQrCodeScanner /></Link>
                                </div>
                                <div className="subscribe">
                                    <Link onClick={handlenotificition}><FaRegBell />
                                    </Link>
                                </div>
                                <div className="loction-info" onClick={handleOpenLocation}>
                                    <span><FaMapMarkerAlt /></span>
                                    <span>West mambalam,<br/> Chennai - 600009</span>

                                </div>

                                <div className="avtar-img">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                            <img src="assets/Image/avtar.png" alt='' />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <DropdownItem as={Link} to="/dashbord">Dashboard</DropdownItem>
                                            <DropdownItem as={Link} to="/wishlist">Wishlist</DropdownItem>
                                            <DropdownItem as={Link} onClick={handledeleteAccount}>Delete Account</DropdownItem>
                                            <DropdownItem as={Link} to="#" onClick={handleLogout}><IoLogOut /> Logout</DropdownItem>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <div className="bottom">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className='justify-content-center'>
                        <Nav className="">
                            <Nav.Link as={Link} to="/">All</Nav.Link>
                            <NavDropdown title="Categories" id="basic-nav-dropdown">
                                <NavDropdown.Item as={Link} to='/automotive'>Automotive</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to=''>Fashion & Clothing</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to=''>Toys & Games</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to=''>Household & Garden</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to=''>Sports & Leisure</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to=''>Food & Drinks</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link as={Link} to="/dicountcoupon">Discount</Nav.Link>
                            <Nav.Link  as={Link} to="/cashbackoffer">Cashback</Nav.Link>
                            <Nav.Link as={Link} to="/voucher">Vouchers</Nav.Link>
                            <Nav.Link as={Link} to="">Wallet</Nav.Link>
                                                        {isApproved ? (
                                <Nav.Link as={Link} to="/merchanthome">Merchants</Nav.Link>
                            ) : (
                                <Nav.Link as={Link} to="/merchantsignup">merchants</Nav.Link> // Change this to your desired link
                            )}
                        </Nav>
                        
                        {!isLoggedIn && (
                            <DropdownButton id="dropdown-basic-button" className="login-dropdown" title="Members Login">
                                <Dropdown.Item as={Link} onClick={handleMemberLogin}>Member Login</Dropdown.Item>
                                <Dropdown.Item as={Link} onClick={handleMerchantLogin}>Merchant Login</Dropdown.Item>
                                <Dropdown.Item as={Link} onClick={handleOperatorLogin}>Operator Login</Dropdown.Item>
                            </DropdownButton>
                        )}
                       
                        <div className="search">
                            <input type="text" name="search" id="search" placeholder='Search for the coupons , deals and  offers' />
                            <button><FaSearch /></button>
                        </div>

                    </Navbar.Collapse>
                </div>
            </Navbar >

            <Modal show={showModal} onHide={handleClose}>
                {modalContent}
            </Modal>

        </>
    )
}

export default Header



