import { useEffect } from 'react';
import { BaseUrl } from './BaseURL/BaseUrl';

const UserDataFetcher = () => {
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token'); 

      if (!token) {
        return; 
      }

      try {
        const response = await fetch(`${BaseUrl}/user/user-details`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // Optional, depending on your API
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        if (data) {
          localStorage.removeItem('UserData');
          localStorage.removeItem('role');
          localStorage.removeItem('is_approved');
          localStorage.removeItem('sub_manager');
          localStorage.removeItem('sub_manager');

          localStorage.setItem('userData', JSON.stringify(data));
          localStorage.setItem('role', data.role);
          localStorage.setItem('is_approved', data.is_approved);
          localStorage.setItem('sub_manager', data.sub_manager);
        }
      } catch (err) {
        console.error('Failed to fetch user data:', err);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 5000); // Fetch every second
    return () => clearInterval(intervalId);
  }, []); 

  return null; 
};

export default UserDataFetcher;
