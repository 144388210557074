
import React, { useEffect, useState,useRef  } from 'react';

import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CuponAddPopup from './CuponAddPopup';
import ApiService from '../../../services/Api'; // Adjust the import path as necessary
import { BaseUrl } from '../../BaseURL/BaseUrl';
import { useParams,useNavigate } from 'react-router-dom';

const Editdealform = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        btype: '',
        categories: '',
        subcategories: '',
        couponcount: '',
        cuponused: '',
        registrationnumber: '',
        image: null,
        ctype: '',
        miniamount: '',
        maxamount: '',
        discamount: '',
        amounttype: '',
        website: '',
        startdate: '',
        enddate: '',
        street1: '',
        street2: '',
        location: '',
        state: '',
        city: '',
        businessrelation: '',
        howusecupon: '',
        condition: '',
        description: '',
    });
    const [imagePreview, setImagePreview] = useState(null);
    useEffect(() => {
        const fetchDealData = async () => {
            try {
                const response = await fetch(`${BaseUrl}/user/get/deal/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    }
                });
 
                if (!response.ok) {
                    throw new Error('Error fetching deal data');
                }

                const data = await response.json();
                console.log(data);
                setFormData(data.data); // Populate formData with existing deal data

                
                if (data.data.image_url) {
                    console.log(data.data.image_url);
                    setImagePreview(data.data.image_url); // Set the URL of the existing image
                }

                setFormData(prevState => ({
                    ...prevState,
                    categories: data.data.categories_id,
                    subcategories: data.data.subcategories_id,
                }));
                
                const selectedCategory = data.data.categories_id;
                if (selectedCategory) {
                    await fetchSubCategories(selectedCategory);
                }

            } catch (error) {
                console.error('Error fetching deal data:', error);
            }
        };

        const fetchSubCategories = async (categoryId) => {
            try {
                const response = await fetch(`${BaseUrl}/user/get/subcategories/${categoryId}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    }
                });
    
                if (!response.ok) {
                    throw new Error('Error fetching subcategories');
                }
    
                const subCategoriesData = await response.json();
                setSubCategories(subCategoriesData); // Set the subcategories based on the fetched data
            } catch (error) {
                console.error('Error fetching subcategories:', error);
            }
        };
    

        fetchDealData();
    }, [id]);

    // const handleFileChange = (e) => {
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         image: e.target.files[0],  // Store the selected file
    //     }));
    // };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            image: file,
        }));

        if (file) {
            const previewUrl = URL.createObjectURL(file);
            setImagePreview(previewUrl);
        }
    };


    const [btype, setbtype] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    const localToken = localStorage.getItem('token'); 
    useEffect(() => {
        const fetchbtype = async () => {
            try {
                const response = await fetch(`${BaseUrl}/user/get/business`, {
                    headers: {
                        'Authorization': `Bearer ${localToken}`
                    }
                });
                
                console.log('Response Status:', response.status);
                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`Error fetching business types: ${response.status} - ${errorText}`);
                }
                
                const data = await response.json();
                setbtype(data);
            } catch (error) {
                console.error('Error fetching business types:', error);
            }
        }; 
    
        fetchbtype();
    }, []);
    
    useEffect(() => {
        const fetchCategories = async () => {
            try { 
                const response = await fetch(`${BaseUrl}/user/get/categories`, {
                    headers: {
                        'Authorization': `Bearer ${localToken}`
                    }
                });
                
                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`Error fetching categories: ${response.status} - ${errorText}`);
                }
    
                const data = await response.json();
                setCategories(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
    
        fetchCategories();
    }, []);
    
    const handleCategoryChange = async (e) => {
        const selectedCategory = e.target.value;
        
        setFormData((prevData) => ({
            ...prevData,
            categories: selectedCategory,
            subcategories: '', 
        }));

        setErrors((prevErrors) => ({ ...prevErrors, categories: '' }));
        setSubmitError('');
        if (selectedCategory) {
            try {
                const response = await fetch(`${BaseUrl}/user/get/subcategories/${selectedCategory}`, {
                    headers: {
                        'Authorization': `Bearer ${localToken}`
                    }
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`Error fetching subcategories: ${response.status} - ${errorText}`);
                }

                const data = await response.json();
                setSubCategories(data);
            } catch (error) {
                console.error('Error fetching subcategories:', error);
            }
        } else {
            setSubCategories([]);
        }
    };

    // const handleFinalConfirmation = () => {
    //     console.log("Navigating to /merchantsignup from handleFinalConfirmation");
    // navigate('/merchantsignup');
    // };
    // const handleFinalCancel = () => {
    //     console.log("Navigating to /merchantsignup from handleFinalCancel");
    //     setShowSecondModal(false)
    //     navigate('/merchantsignup');
    //     };

    const navigateToMerchantSignup = () => {
        console.log("Navigating to /merchantsignup");
        navigate('/merchantsignup');
    };
    
    const handleFinalConfirmation = () => {
        navigateToMerchantSignup();
    };
    
    const handleFinalCancel = () => {
        setShowSecondModal(false);
        navigateToMerchantSignup();
    };

    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false);
    const [showFirstModal, setShowFirstModal] = useState(false);
    const [showSecondModal, setShowSecondModal] = useState(false); 
    const [submitError, setSubmitError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (name === 'categories') {
            setFormData((prevData) => ({
                ...prevData,
                subcategories: '', 
            }));
            
        }

        if (name === 'ctype' && value === 'voucher') {
            setFormData((prevData) => ({
                ...prevData,
                discamount: 0,
                amounttype: 0,
            }));
        }
        
        

        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
        setSubmitError('');
    };
    const refs = {
        btype: useRef(null),
        categories: useRef(null),
        subcategories: useRef(null),
        couponcount: useRef(null),
        cuponused: useRef(null),
        registrationnumber: useRef(null),
        ctype: useRef(null),
        miniamount: useRef(null),
        maxamount: useRef(null),
        discamount: useRef(null),
        amounttype: useRef(null),
        website: useRef(null),
        startdate: useRef(null),
        enddate: useRef(null),
        street1: useRef(null),
        street2: useRef(null),
        location: useRef(null),
        state: useRef(null),
        city: useRef(null),
        businessrelation: useRef(null),
        howusecupon: useRef(null),
        condition: useRef(null),
        description: useRef(null),
    };

    const validateForm = () => {
        const newErrors = {};
    
        if (!formData.btype) newErrors.btype = 'Business type is required';
        if (!formData.categories) newErrors.categories = 'Categories are required';
        if (!formData.subcategories) newErrors.subcategories = 'Sub-categories are required';
        if (!formData.couponcount) newErrors.couponcount = 'Coupon Count is required';
        if (!formData.cuponused) newErrors.cuponused = 'Coupon used field is required';
        if (!formData.registrationnumber) newErrors.registrationnumber = 'Registration number is required';
        if ((formData.ctype === 'discount' || formData.ctype === 'cashback') && !formData.discamount) {
            newErrors.discamount = 'Discount is required.';
        }
        if ((formData.ctype === 'discount' || formData.ctype === 'cashback') && !formData.amounttype) {
            newErrors.amounttype = 'Amount type is required.';
        }
        if (!formData.website) newErrors.website = 'website is required';
        if (!formData.ctype) newErrors.ctype = 'Coupon type is required';
        if (!formData.miniamount) newErrors.miniamount = 'Minimum amount purchase is required';
        if (!formData.maxamount) newErrors.maxamount = 'Maximum amount purchase is required';
        if (!formData.startdate) newErrors.startdate = 'Deals started date is required';
        if (!formData.enddate) newErrors.enddate = 'Deals ended date is required';
        if (!formData.street1) newErrors.street1 = 'Street 1 is required';
        if (!formData.street2) newErrors.street2 = 'Street 2 is required';
        if (!formData.location) newErrors.location = 'Location is required';
        if (!formData.state) newErrors.state = 'State is required';
        if (!formData.city) newErrors.city = 'City is required';
        if (!formData.businessrelation) newErrors.businessrelation = 'Business relationship is required';
        if (!formData.howusecupon) newErrors.howusecupon = 'Instructions on how to use the coupon are required';
        if (!formData.condition) newErrors.condition = 'Terms and conditions are required';
        if (!formData.description) newErrors.description = 'Description is required';
    
        if (formData.miniamount && isNaN(formData.miniamount)) newErrors.miniamount = 'Minimum amount must be a number';
        if (formData.maxamount && isNaN(formData.maxamount)) newErrors.maxamount = 'Maximum amount must be a number';
    
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            const firstErrorField = Object.keys(newErrors)[0];
            if (refs[firstErrorField] && refs[firstErrorField].current) {
                refs[firstErrorField].current.focus();
            }
        }

        return Object.keys(newErrors).length === 0;
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        setShowFirstModal(true); 
    };
    const handleFirstConfirmation = () => {
        setShowFirstModal(false); 
        setShowSecondModal(true); 
    };
   

    const applycoupon = async (data) => {
        try {
            const formData = new FormData();
    
            // Append all fields to formData
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
    
            // If there's an image, append it to the formData
            if (data.image) {
                formData.append('image', data.image);
            }
            formData.append('editID', id);
    
            const response = await fetch(`${BaseUrl}/user/updatecoupon`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    // 'Content-Type' should not be set; it's handled automatically
                },
                body: formData,
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }
    
            return await response.json();
        } catch (error) {
            console.error('Error during coupon application:', error);
            throw error; 
        }
    };
    
    const handleConfirm = async (e) => {
        setIsLoading(true); 
         
        try {
            const result = await applycoupon(formData);
           
            if (result.status === 200 || result.status === 201) {
                setSuccessMessage('Deal updated successfully!');
                setShowFirstModal(false); 
                setShowSecondModal(true); 
               
             
              
            } else {
                setSubmitError('Failed to add deal. Please try again.');
            }
        } catch (err) {
            if (err.errors && err.status === 422) {
                const backendErrors = err.errors
                console.log(backendErrors);
                const newErrors = {};
                backendErrors.forEach((error) => {
                    const fieldName = error.split(' ')[0].toLowerCase(); 
                    newErrors[fieldName] = error;
                });
                setErrors(newErrors);
            } else {
                setSubmitError(err.message || 'An unexpected error occurred.');
            }
        }
        finally {
            setIsLoading(false); // End loading in both success and error cases
        }
    };

    return ( 
        <>
            <div className="add-new-deals-outer">
                <div className="container">
                    <div className="add-new-deals-inner">
                        <div className="heading">
                            <h3>Edit Deals</h3>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="btype">Business Type</label>
                                        <select    ref={refs.btype}
                                            id="btype"
                                            name="btype"
                                            value={formData.btype}
                                            onChange={handleChange}
                                        >
                                         <option value="" disabled>Select Business type</option>
                                        {btype.map((type) => (
                                            <option key={type.id} value={type.name}>{type.name}</option>
                                        ))}
                                        </select>
                                       
                                        {errors.btype && <span className="error">{errors.btype}</span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="categories">Categories</label>
                                        <select ref={refs.categories}
                                            id="categories"
                                            name="categories"
                                            value={formData.categories}
                                           onChange={handleCategoryChange}
                                        >
                                             <option value="" disabled>Select category</option>
                                            {categories.map((category) => (
                                                <option key={category.id} value={category.id}>{category.category_name}</option>
                                            ))}
                                        </select>
                                        {errors.categories && <span className="error">{errors.categories}</span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="subcategories">Sub-Category</label>
                                        <select ref={refs.subcategories}
                                            id="subcategories"
                                            name="subcategories"
                                            value={formData.subcategories}
                                            onChange={(e) => setFormData({ ...formData, subcategories: e.target.value })}
                                            disabled={!subCategories.length} 
                                        >
                                        <option value="" disabled>Select subcategory</option>
                                        {subCategories.map((subCategory) => (
                                            <option key={subCategory.id} value={subCategory.id}>{subCategory.name}</option>
                                        ))}
                                        </select>
                                        {errors.subcategories && <span className="error">{errors.subcategories}</span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="couponcount">Coupon Count</label>
                                        <input type="number" ref={refs.couponcount} name="couponcount" value={formData.couponcount} onChange={handleChange} placeholder="Enter coupon count" />
                                        {errors.couponcount && <p className="error">{errors.couponcount}</p>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="cuponused">Coupon Used</label>
                                        <select ref={refs.cuponused} 
                                            id="cuponused"
                                            name="cuponused"
                                            value={formData.cuponused}
                                            onChange={handleChange}
                                        >
                                            <option value="" disabled>Select coupon used type</option>
                                            <option value="online">online</option>
                                            <option value="offline">offline</option>
                                        </select>
                                        {errors.cuponused && <span className="error">{errors.cuponused}</span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="registrationnumber">Registration Number</label>
                                        <input  ref={refs.registrationnumber} 
                                            type="text"
                                            id="registrationnumber"
                                            name="registrationnumber"
                                            placeholder="Registration number"
                                            value={formData.registrationnumber}
                                            onChange={handleChange}
                                        />
                                        {errors.registrationnumber && <span className="error">{errors.registrationnumber}</span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="ctype">Coupon Type</label>
                                        <select  ref={refs.ctype} 
                                            id="ctype"
                                            name="ctype"
                                            value={formData.ctype}
                                            onChange={handleChange}
                                        >
                                            <option value="" disabled>Select coupon type</option>
                                            <option value="voucher">Voucher</option>
                                            <option value="discount">Discount</option>
                                            <option value="cashback">Cashback</option>
                                        </select>
                                        {errors.ctype && <span className="error">{errors.ctype}</span>}
                                    </div>
                                </div>

                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="discamount">Discount</label>
                                        <input  ref={refs.discamount} 
                                            type="text"
                                            id="discamount"
                                            name="discamount"
                                            placeholder="Discount"
                                            value={formData.discamount}
                                            onChange={handleChange}
                                            disabled={formData.ctype === 'voucher'} 
                                        />
                                        {errors.discamount && <span className="error">{errors.discamount}</span>}
                                    </div>
                                </div>

                                {(formData.ctype === 'discount' || formData.ctype === 'cashback') && (
                                    <div className="col-12 col-md-4">
                                        <div className="inputgroup">
                                            <label htmlFor="amounttype">Amount Type</label>
                                            <select  ref={refs.amounttype} 
                                                id="amounttype"
                                                name="amounttype"
                                                value={formData.amounttype}
                                                onChange={handleChange}
                                            >
                                                <option value="" disabled>Select amount type</option>
                                                <option value="amount">Amount</option>
                                                <option value="percentage">Percentage</option>
                                            </select>
                                            {errors.amounttype && <span className="error">{errors.amounttype}</span>}
                                        </div>
                                    </div>
                                )}


                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="miniamount">Minimum Amount Purchase</label>
                                        <input ref={refs.miniamount} 
                                            type="text"
                                            id="miniamount"
                                            name="miniamount"
                                            placeholder="Purchase amount of coupons"
                                            value={formData.miniamount}
                                            onChange={handleChange}
                                        />
                                        {errors.miniamount && <span className="error">{errors.miniamount}</span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="maxamount">Maximum Amount Purchase</label>
                                        <input ref={refs.maxamount} 
                                            type="text"
                                            name="maxamount"
                                            id="maxamount"
                                            placeholder="Purchase amount of coupons"
                                            value={formData.maxamount}
                                            onChange={handleChange}
                                        />
                                        {errors.maxamount && <span className="error">{errors.maxamount}</span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="website">Website</label>
                                        <input ref={refs.website} 
                                            type="text"
                                            id="website"
                                            name="website"
                                            placeholder="Website"
                                            value={formData.website}
                                            onChange={handleChange}
                                        />
                                    {errors.website && <span className="error">{errors.website}</span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="startdate">Start Date</label>
                                        <input ref={refs.startdate} 
                                            type="date"
                                            id="startdate"
                                            name="startdate"
                                            value={formData.startdate}
                                            onChange={handleChange}
                                        />
                                        
                                   {errors.startdate && <span className="error">{errors.startdate}</span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="enddate">End Date</label>
                                        <input ref={refs.enddate} 
                                            type="date"
                                            id="enddate"
                                            name="enddate"
                                            value={formData.enddate}
                                            onChange={handleChange}
                                        />
                                        {errors.enddate && <span className="error">{errors.enddate}</span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="street1">Street 1</label>
                                        <input ref={refs.street1} 
                                            type="text"
                                            name="street1"
                                            id="street1"
                                            placeholder="Street Address"
                                            value={formData.street1}
                                            onChange={handleChange}
                                        />
                                        {errors.street1 && <span className="error">{errors.street1}</span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="street2">Street 2</label>
                                        <input ref={refs.street2} 
                                            type="text"
                                            name="street2"
                                            id="street2"
                                            placeholder="Street Address"
                                            value={formData.street2}
                                            onChange={handleChange}
                                        />
                                        
                                        {errors.street2 && <span className="error">{errors.street2}</span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="location">Location</label>
                                        <input ref={refs.location} 
                                            type="text"
                                            name="location"
                                            id="location"
                                            placeholder="Location"
                                            value={formData.location}
                                            onChange={handleChange}
                                        />
                                        {errors.location && <span className="error">{errors.location}</span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="state">State</label>
                                        <input ref={refs.state} 
                                            type="text"
                                            name="state"
                                            id="state"
                                            placeholder="State"
                                            value={formData.state}
                                            onChange={handleChange}
                                        />
                                        {errors.state && <span className="error">{errors.state}</span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="city">City</label>
                                        <input ref={refs.city} 
                                            type="text"
                                            name="city"
                                            id="city"
                                            placeholder="City"
                                            value={formData.city}
                                            onChange={handleChange}
                                        />
                                     {errors.city && <span className="error">{errors.city}</span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="businessrelation">Business Relationship</label>
                                        <input ref={refs.businessrelation} 
                                            type="text"
                                            name="businessrelation"
                                            id="businessrelation"
                                            placeholder="Business Relationship"
                                            value={formData.businessrelation}
                                            onChange={handleChange}
                                        />
                                        {errors.businessrelation && <span className="error">{errors.businessrelation}</span>}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="inputgroup">
                                        <label htmlFor="howusecupon">How to Use the Coupon</label>
                                        <textarea name="howusecupon" id="howusecupon"   value={formData.howusecupon}
                                            onChange={handleChange} placeholder="Enter how to use the coupon"></textarea>
                                               {errors.howusecupon && <span className="error">{errors.howusecupon}</span>}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="inputgroup">
                                        <label htmlFor="condition">Terms & Conditions</label>
                                        <textarea name="condition" id="condition" value={formData.condition} onChange={handleChange} placeholder="Enter how to use the coupon"></textarea>
                                        {errors.condition && <span className="error">{errors.condition}</span>}
                                    </div>
                                </div>                                
                                <div className="col-12">
                                    <div className="inputgroup">
                                        <label htmlFor="description">Description</label>
                                        <textarea
                                            name="description"
                                            id="description"
                                            placeholder="Description"
                                            value={formData.description}
                                            onChange={handleChange}
                                        />
                                        
                                    {errors.description && <span className="error">{errors.description}</span>}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="inputgroup">
                                    <div class="upload-image">
                                        <label htmlFor="image">Upload Image</label>
                                        {imagePreview && (
                                            <img
                                                src={imagePreview}
                                                alt="Preview"
                                                style={{ width: '100px', height: '100px' }} // Adjust width as needed
                                            />
                                        )}
                                        <img src="/assets/Image/uploadimg.png" alt="" />
                                        <input type="file" name="image" onChange={handleFileChange} />
                                        {errors.image && <p className="error">{errors.image}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                <div className="submit-button">
                                    <Link to='' onClick={handleSubmit} disabled={isLoading} >{isLoading ? 'Loading...' : 'Publish'}</Link>
                                </div>

                                </div>
                                {submitError && <span className="error">{submitError}</span>}
                                {successMessage && <span className="success">{successMessage}</span>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Modal show={showFirstModal} onHide={() => setShowFirstModal(false)}>
                <CuponAddPopup 
                    close={() => setShowFirstModal(false)} 
                    onConfirm={handleConfirm}
                    onFirstConfirmation={handleFirstConfirmation}
                />
            </Modal>
            <Modal show={showSecondModal} onHide={() => setShowSecondModal(false)}>
                <div className="cuponadd-outer">
                    <div className="cuponadd-inner">
                        <div className="heading">
                            <h3>Final Verification</h3>
                        </div>
                        <p>Do you want first preference on your coupon sales?</p>

                        <div className="button-group">
                        <Link 
                            className="button" 
                            href="#" 
                            onClick={(e) => {
                                e.preventDefault();
                                handleFinalConfirmation();
                            }}
                        >
                            Yes
                        </Link>
                        <Link 
                            className="outline-button" 
                            href="#" 
                            onClick={(e) => {
                                e.preventDefault();
                                handleFinalCancel();
                            }}
                        >
                            No
                        </Link>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Editdealform;
