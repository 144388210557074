import React from 'react'
import MearchantHeader from './MearchantHeader'
import OperatorLogin from './MearchantOperator/OperatorLogin'
import PageBanner from '../PageBanner'

const OperatorLoginPage = () => {
  return (
    <>
      <MearchantHeader/>
      <PageBanner title='Create operator login'/>
      <OperatorLogin/>
    </>
  )
}

export default OperatorLoginPage
