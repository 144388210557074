import React, { useState } from 'react'
import { FaX } from 'react-icons/fa6'
import { Link, useNavigate } from 'react-router-dom'
// import { BaseUrl } from '../BaseURL/BaseUrl';
import { useAuth } from '../../context/AuthContext';
import ApiService from '../../services/Api';
const Operatorlogin = ({ onClose }) => {

  const [formData, setFormData] = useState({
    email: '',
    password: '',
});
const [rememberMe, setRememberMe] = useState(false);
const [errors, setErrors] = useState({});
const [submitError, setSubmitError] = useState('');
const [successMessage, setSuccessMessage] = useState('');
const navigate = useNavigate();
const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
        ...prevData,
        [name]: value
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' })); 
    setSubmitError(''); 
};

const handleCheckboxChange = () => {
    setRememberMe((prev) => !prev);
};

const validateForm = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid';

    if (!formData.password) newErrors.password = 'Password is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; 
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return; 

    try {
        const result = await ApiService.Manager_cashier(formData);
        if (result.status === 200) {
          localStorage.removeItem('UserData');
          localStorage.removeItem('role');
          localStorage.removeItem('token');
          localStorage.removeItem('is_approved');
          localStorage.removeItem('sub_manager');

          localStorage.setItem('UserData', JSON.stringify(result));
          localStorage.setItem('role', result.role);
          localStorage.setItem('sub_manager', result.success.sub_manager);
          localStorage.setItem('token', result.success.token);
          localStorage.setItem('is_approved', result.success.is_approved);
            setSuccessMessage('Login successful! Redirecting...'); 
            setTimeout(() => {
                onClose(); 
            }, 2000);
            switch (result.role) {
              case 'cashier':
                navigate('/shoper');
                break;
              case 'manager':
                window.location.href = '/operatordesktop'; 
                break;
              default:
                navigate('/'); 
                break;
            }
        }else {
          setSubmitError('Login failed. Please try again.');
      }
  } catch (err) {
    const status = err.status;
    const Errors = err.errors;
      if (status === 401) {
          setSubmitError(Errors);
      }else if (status === 403) {
        setSubmitError(Errors); 
    } else if (status === 404) {
        setSubmitError(Errors);
    }        
       else {
          setSubmitError(err.message || 'An unexpected error occurred.'); 
      }
  }
};



  return (
    <>

      <div className="login-outer operator-login">
        <div className="login-inner row">
          <div className="col-12 col-sm-6 left">
            <div className="img-content">
              <img src="assets/Image/Merchant.png" alt="" />
            </div>
          </div>
          <div className="col-12 col-sm-6 right">
            <button onClick={onClose}><FaX /></button>
            <div className="inner">

              <div className="heading" style={{ textAlign: 'center' }}>
                <h3>Manager / Cashier Sign in
                </h3>
              </div>
              <form method="post" onSubmit={handleSubmit}>
                            <input
                                type="email"
                                name='email'
                                id='email'
                                placeholder='E-mail id'
                                value={formData.email}
                                onChange={handleChange}
                            />
                            {errors.email && <span className="error">{errors.email}</span>}

                            <input
                                type="password"
                                name='password'
                                id='password'
                                placeholder='Password'
                                value={formData.password}
                                onChange={handleChange}
                            />
                            {errors.password && <span className="error">{errors.password}</span>}

                            <div className="privacy-check remember-me">
                                <input
                                    type="checkbox"
                                    checked={rememberMe}
                                    onChange={handleCheckboxChange}
                                />
                                <label>Remember Me</label>
                            </div>

                            <Link to='' className='button' onClick={handleSubmit}>Sign In</Link>
                            {submitError && <span className="error">{submitError}</span>}
                            {successMessage && <span className="success">{successMessage}</span>}
                        </form>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Operatorlogin